import React from "react";
import { Layout } from "../components/Layout";
import Section from "../components/Section";

const NotFoundPage = () => (
  <Layout>
    <Section.Container id="404">
      <div>
        <h1>404</h1>
        <h2>There isn't anything here</h2>
      </div>
    </Section.Container>
  </Layout>
);

export default NotFoundPage;
